.casino {
  &-categories {
    display: flex;
    justify-content: flex-start;
    /* overflow: scroll; */
    width: 100%;
    max-width: 100%;

    @media screen and (max-width: 740px) {
      justify-content: flex-start;
    }

    &__category {
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      user-select: none;
      background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
      margin: 0 4px 0 0;
      border-radius: 4px;

      @include media-breakpoint-down(md) {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
      }

      &:last-child {
        margin: 0;
      }

      img {
        margin: 0 0 18px 0;
      }

      &-counter {
        position: absolute;
        right: 6px;
        top: 6px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #c4c4c4;

        @include media-breakpoint-down(md) {
          top: 4px;
          right: 4px;
          font-size: 10px;
        }
      }

      &-title {
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        position: absolute;
        bottom: 14px;
        left: 0;
        color: #d8dadf;

        @include media-breakpoint-down(md) {
          font-size: 12px;
          bottom: 8px;
        }
      }

      &:hover,
      &--selected {
        background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
        cursor: pointer;

        .casino-categories__category-title {
          color: #fbfdfe;
        }
      }
      &--selected {
        pointer-events: none;
      }
    }

    > .slide-auto-width {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  &-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 0 0;
    max-width: 100%;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 8px 0 0 0;
    }
  }

  &-search-wrapper {
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      margin: 0;
      width: 100%;
      justify-content: space-between;
    }
  }

  &-filters-toggler {
    width: 58px;
    height: 48px;
    background: #293143;
    border-radius: 4px;
    display: none;
    align-items: center;
    justify-content: center;
    margin: 0 4px 0 0;

    svg {
      color: #bdc0c7;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }

  &-filters {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
      display: none;
      margin: 8px 0 0 0;

      &.shown {
        display: flex;
      }
    }

    .casino-filter {
      user-select: none;
      position: relative;
      white-space: nowrap;

      @include media-breakpoint-down(sm) {
        margin: 0 0 8px 0;
        width: 100%;

        &:last-child {
          margin: 0;
        }
      }

      &:last-child {
        margin: 0;
      }

      &__selected {
        font-size: 16px;
        line-height: 30px;
        background: $scolor2;
        border-radius: 4px;
        display: inline-block;
        margin: 0 8px 0 0;
        padding: 7px 34px 7px 16px;
        cursor: pointer;
        position: relative;
        color: #fff;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }

        svg {
          position: absolute;
          right: 12px;
          top: 14px;
        }
      }

      &__options {
        position: absolute;
        display: none;
        background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
        border-radius: 5px;
        min-width: 200px;
        max-width: 250px;
        padding: 8px 0;
        top: 50px;
        z-index: 100;

        @include media-breakpoint-down(sm) {
          width: 100%;
          max-width: none;
        }

        &.open {
          display: block;
        }
      }

      &__option {
        font-size: 16px;
        line-height: 44px;
        color: #fff;
        cursor: pointer;
        padding: 0 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        padding-right: 38px;

        &--clear-all {
          padding: 0;
          text-align: center;
          margin-top: 4px;
        }

        &-selected {
          width: 14px;
          height: 14px;
          display: block;
          position: absolute;
          right: 12px;
          top: 14px;
          border: 1px solid #838fa3;
          border-radius: 2px;

          &.active {
            background-color: #1e2637;
            border-color: #1e2637;

            &:after {
              content: "";
              position: absolute;
              width: 4px;
              height: 8px;
              left: 4px;
              top: 1px;
              border-bottom: 1px solid #fff;
              border-right: 1px solid #fff;
              transform: rotate(45deg);
            }
          }
        }

        &.showCount {
          padding: 0 52px 0 38px;

          .casino-filter__option-selected {
            right: auto;
            left: 16px;
          }
        }

        &-count {
          position: absolute;
          right: 12px;
          top: 0;
          font-size: 14px;
          color: #d8dadf;
        }

        &:hover,
        &.active {
          background-color: #40598e;

          .casino-filter__option-selected.active {
            background-color: #1e2637;
            border-color: #1e2637;
          }
        }
      }
    }
  }

  &-grid {
    margin: 16px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include media-breakpoint-down(md) {
      margin: 8px 0 0 0;
    }

    &__card {
      width: 25%;
      max-width: 25%;
      overflow: hidden;
      padding: 4px;
      position: relative;
      cursor: pointer;
      margin: 0 0 8px 0;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-down(lg) {
        width: 33.33%;
        max-width: 33.33%;
      }

      @media screen and (max-width: 750px) {
        width: 50%;
        max-width: 50%;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }

      .game-hot-badge {
        position: absolute;
        top: 12px;
        left: 12px;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: #c5203e;
        display: flex;
        gap: 2px;

        &__title {
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          color: #ffffff;
        }

        @include media-breakpoint-down(sm) {
          top: 8px;
          left: 8px;
        }
      }

      .game-title {
        font-size: 18px;
        line-height: 21px;
        color: #fbfdfe;
        white-space: nowrap;
        max-width: calc(100% - 36px);
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0;
        margin-bottom: 8px;

        @include media-breakpoint-down(lg) {
          font-size: 16px;
          line-height: 19px;
        }
        @include media-breakpoint-down(md) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      .provider-title {
        font-size: 14px;
        line-height: 16px;
        color: #838fa3;
        max-width: calc(100% - 36px);
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        padding: 0;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .casino-grid__card-zoom {
        overflow: hidden;
        margin-bottom: 16px;
        position: relative;

        img {
          transition: transform 0.2s;
        }
      }

      &:hover {
        .casino-grid__card-zoom {
          img {
            transform: scale(1.05);
          }
        }
      }
    }

    &__add-favourite {
      position: absolute;
      bottom: 12px;
      right: 8px;

      svg {
        color: #838fa3;
      }

      &:hover {
        svg {
          color: rgba($color: #73e698, $alpha: 0.7);
        }
      }

      &.active {
        svg {
          color: #73e698;
        }
      }
    }
  }

  &-load-more {
    display: flex;
    justify-content: center;
    margin: 48px 0;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;

    @include media-breakpoint-down(md) {
      margin: 24px 0;
    }

    p {
      font-size: 16px;
      line-height: 19px;
      color: #838fa3;
      text-align: center;
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }

    button {
      margin: 5px 12px;
      background: #73e698;
      color: #000;
      border-radius: 5px;
      width: 140px;
      max-width: 90%;
      line-height: 34px;
      border: none;
      padding: 0;
      outline: none;

      &:disabled {
        background-color: gray;
      }
    }

    &__spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      min-height: 45px;
      overflow: hidden;

      svg {
        width: 36px !important;
        height: 36px !important;
        color: #838fa3;
      }
    }
  }

  &-related-games {
    h2 {
      color: #fbfdfe;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
    }

    .casino-grid {
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;

      &__card {
        @media screen and (max-width: 750px) {
          min-width: 33.33%;
        }
        @media screen and (max-width: 450px) {
          min-width: 50%;
        }
      }
    }
  }

  &-pagination {
    height: 42px;
    display: flex;
    justify-content: flex-end;
    margin: 12px 0 12px;

    @include media-breakpoint-down(md) {
      height: 32px;
      margin: 6px;
    }

    a {
      @include media-breakpoint-down(md) {
        &.other {
          display: none;
        }
      }

      &,
      &:hover {
        line-height: 42px;
        text-decoration: none;
        background: $scolor2;
        color: #d8dadf;
        font-size: 18px;
        padding: 0 12px;

        @include media-breakpoint-down(md) {
          font-size: 14px;
          line-height: 32px;
        }
      }

      &:hover:not(.current) {
        color: #fff;
      }

      &.current {
        background: #525c6e;
      }

      &.prev,
      &.next {
        padding: 0 4px;

        @include media-breakpoint-down(md) {
          padding: 0 12px;
        }
      }

      &:first-child {
        border-radius: 5px 0 0 5px;
        padding: 0 16px;

        @include media-breakpoint-down(md) {
          padding: 0 12px;
        }
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
        padding: 0 16px;

        @include media-breakpoint-down(md) {
          padding: 0 12px;
        }
      }
    }
  }

  &-bets {
    margin: 12px 0 12px 0;

    &__title {
      background: $scolor2;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      position: relative;
      user-select: none;
      cursor: pointer;

      h2 {
        margin: 0 0 0 28px;
        padding: 0;
        font-size: 16px;
        line-height: 19px;
        color: #d8dadf;
      }

      svg {
        position: absolute;
        top: 13px;
        left: 12px;
        font-size: 12px;
        color: #d8dadf;
      }
    }

    &__table {
      width: 100%;

      tr {
        display: flex;
      }

      td {
        font-size: 14px;
        line-height: 21px;
        color: #525c6e;
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
          font-size: 14px;
          width: -webkit-fill-available !important;
          line-height: 18px;
          padding: 8px;
        }

        &:first-child {
          justify-content: flex-start;
          padding: 12px 12px 12px 4px;
        }
      }

      &-datetime {
        width: 10%;
      }

      &-id {
        width: 20%;
        white-space: nowrap;
      }

      &-status {
        width: 20%;
      }

      &-game {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40%;

        &-title {
          color: #fbfdfe;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-provider {
          color: #525c6e;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media screen and (max-width: 400px) {
          display: none !important;
        }
      }

      &-sum {
        width: 20%;
        white-space: nowrap;
      }

      tr.win {
        .casino-bets__table-id,
        .casino-bets__table-status,
        .casino-bets__table-sum {
          color: #73e698;
        }
      }

      tr.lose {
        .casino-bets__table-id,
        .casino-bets__table-status,
        .casino-bets__table-sum {
          color: #ff7a73;
        }
      }
    }
  }

  &-breadcrumbs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    height: 40px;
    background: $scolor2;
    margin: 12px 0;
    user-select: none;

    a {
      text-decoration: none;
      font-size: 16px;
      line-height: 19px;
      color: #c4c4c4;
      position: relative;
      margin: 0 20px 0 0;

      &:hover {
        color: #fbfdfe;
      }

      &:after {
        content: "▸";
        display: block;
        position: absolute;
        right: -15px;
        top: -1px;
        color: #c4c4c4;
        pointer-events: none;
      }

      &:last-child {
        cursor: default;
        pointer-events: none;

        &:after {
          display: none;
        }
      }
    }
  }

  &-game__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;

    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
      margin: 4px 0;
    }

    &-left {
      display: flex;
      position: relative;
      padding: 0 0 0 44px;
      align-items: flex-end;
      max-width: 60%;
      white-space: nowrap;

      @media screen and (max-width: 700px) {
        width: 100%;
        max-width: 100%;
      }

      &-add-favourite {
        position: absolute;
        left: 16px;
        top: -1px;
        cursor: pointer;

        svg {
          color: #838fa3;
        }

        &.active,
        &:hover {
          svg {
            color: #73e698;
          }
        }
      }

      h1 {
        margin: 0;
        color: #fbfdfe;
        font-size: 18px;
        line-height: 21px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      a.provider {
        color: #838fa3;
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 0 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        user-select: none;
        padding: 0;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    &-right {
      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }

    &-menu {
      display: flex;
      color: #838fa3;
      font-size: 16px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @media screen and (max-width: 700px) {
        justify-content: flex-end;
        margin-top: 12px;
      }

      .btn-top-up {
        border-radius: 5px;
        white-space: nowrap;
      }

      &-button {
        background-color: $scolor2;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        margin: 0 4px 0 0;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        svg {
          color: #838fa3;
        }
      }

      span {
        padding: 0 0 3px;
        margin: 0 6px 0 0;
        white-space: nowrap;
        cursor: pointer;
      }

      .toggle {
        margin-right: 8px;

        div {
          color: #838fa3;
          font-size: 16px;
          font-weight: normal;
        }

        &:before {
          height: 16px;
          width: 26px;
          background-color: #525c6e;
        }

        span {
          border-color: #525c6e;
          background-color: $scolor2;
          width: 16px;
          height: 16px;
          left: 0;
          top: 4px;
        }

        input[type="checkbox"]:checked + span {
          left: 10px;
        }
      }
    }
  }

  &-game-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;

    svg {
      font-size: 48px;
    }
  }

  &-game-container {
    position: relative;
    margin-bottom: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 250px;
    max-height: calc(100vmin - 150px);
    height: 1160px;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;

    iframe {
      overflow: hidden;
      height: 1160px;
      border: 0;
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      z-index: 999;
    }

    &:not(.gameStarted):after {
      content: "";
      display: block;
      position: absolute;
      background: #171b27;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      z-index: 100;
    }
  }

  &-game-deposit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 101;

    &__refill-currency {
      color: #fff;
      height: 34px;
      display: flex;
      align-items: center;
      background: #525c6e;
      border-radius: 0 4px 4px 0;
      padding: 0 10px;
      font-size: 0.875rem;
      line-height: 1.5;
    }

    &__low-balance {
      display: block;
      max-width: 90%;
      margin: 0 0 12px;
      font-size: 14px;
      color: #a1a6b0;

      a {
        color: #d8dadf;

        &:hover {
          text-decoration: none;
        }
      }

      span {
        color: #73e698;
      }
    }

    &__form-container {
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }
    }

    &__close {
      position: absolute;
      right: 24px;
      top: 12px;
      font-size: 20px;
      cursor: pointer;
      color: #a1a6b0;
    }

    &__form {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    label {
      margin: 0 12px 0 0;
      color: #d8dadf;

      @include media-breakpoint-down(xs) {
        width: 100%;
        text-align: center;
        margin: 0 0 8px;
      }
    }

    input,
    input:active,
    input:hover,
    input:focus,
    input:disabled {
      background: $scolor2;
      border-radius: 4px 0px 0px 4px;
      border-color: transparent;
      height: 34px;
      max-width: 150px;
      font-size: 14px;

      &::placeholder {
        color: #838fa3;
      }
    }

    .bt-dropdown-toggle {
      height: 34px;
      background: #525c6e;
      border-radius: 0 4px 4px 0;
      width: 80px;
    }

    .dropdown-menu {
      width: 80px;
      margin-top: 4px;
    }

    .dropdown-item {
      border-radius: 0;
      padding: 0 8px;
      font-size: 12px;
      line-height: 24px;
      width: 80px;
    }

    button.deposit-button {
      background: #73e698;
      color: #000;
      border-radius: 5px;
      width: 180px;
      line-height: 38px;
      border: none;
      padding: 0;
      white-space: nowrap;
      margin: 18px 0;
      outline: none;

      > svg {
        display: inline;
        margin: 0 0 0 8px;
      }

      @include media-breakpoint-down(xs) {
        margin: 8px 0;
        width: 120px;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(md) {
        margin: 0;
      }
    }

    a {
      text-decoration: underline;
      color: #d8dadf;
      font-size: 14px;
      line-height: 16px;
    }

    &--social {
      .bt-dropdown-toggle {
        border-radius: 4px;
      }

      .casino-game-deposit__buttons {
        margin: 0;
      }
    }
  }

  &-search {
    width: 100%;
    max-width: 300px;

    @include media-breakpoint-down(md) {
      max-width: calc(100% - 54px);
    }

    .input-group-prepend {
      .input-group-text {
        border: none !important;
        border-radius: 5px 0 0 5px !important;
      }
    }

    .input-group-append {
      button {
        border: none !important;
        border-radius: 0 5px 5px 0 !important;
      }
    }

    input,
    input:focus {
      border: none !important;
    }
  }

  &-history-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
    color: #525c6e;
    overflow: hidden;

    svg {
      cursor: pointer;
      user-select: none;

      &:hover {
        color: #fff;
      }
    }

    &.win {
      .casino-history-item__betAmount,
      .casino-history-item__sum {
        color: #73e698;
      }
    }

    &.lose {
      .casino-history-item__betAmount,
      .casino-history-item__sum {
        color: #ff7a73;
      }
    }

    > div {
      padding: 12px;
      box-sizing: border-box;

      @include media-breakpoint-down(sm) {
        padding: 4px;
      }
    }

    &__date {
      width: 20%;

      @include media-breakpoint-down(sm) {
        width: 50%;
      }
    }

    &__betAmount {
      width: 20%;
      white-space: nowrap;

      @include media-breakpoint-down(sm) {
        width: 50%;
      }
    }

    &__game {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40%;

      &-title {
        width: 100%;
        color: #fbfdfe;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }

      &-provider {
        width: 100%;
        color: #525c6e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }

      @include media-breakpoint-down(sm) {
        width: 50%;
        .casino-history-item__game-title {
          text-align: left;
          padding-left: 22px;
        }
        .casino-history-item__game-provider {
          text-align: left;
          padding-left: 22px;
        }
      }
    }

    &__sum {
      width: 20%;
      margin: 0 0 0 auto;
      white-space: nowrap;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @include media-breakpoint-down(sm) {
        width: 50%;
      }
    }
  }

  .casino-grid__card-zoom-geoip {
    width: 100%;
    height: 100%;
    background: rgba(73, 73, 73, 0.8);
    z-index: 9999;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px;
    color: #fff;

    p {
      display: none;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    &:hover {
      p {
        display: inline-block;
      }
    }
  }
}

.casino-grid__card-zoom-geoip {
  width: 100%;
  height: 100%;
  background: rgba(73, 73, 73, 0.8);
  z-index: 1;
  display: flex;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 6px;
  color: #fff;

  p {
    display: none;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  &:hover,
  &.visible {
    p {
      display: inline-block;
    }
  }
}

.mobileGame {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  background: #000;
  z-index: 99998;
  margin: 0 !important;
  max-height: none !important;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    position: fixed;
    font-size: 32px;
    top: 12px;
    left: 12px;
    z-index: 99999;
  }

  iframe {
    position: relative !important;
    min-height: auto !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.casino-pagination {
  display: flex;
  justify-content: space-between;
  height: auto;
  flex-wrap: wrap;
  margin: 0;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  &-container {
    display: flex;
    user-select: none;

    @include media-breakpoint-down(sm) {
      padding-bottom: 20px;
    }
    @media screen and (max-width: 360px) {
      display: none;
    }
  }

  &-item {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    background-color: #1e2637;
    color: white;
    width: 46px;
    height: 34px;
    cursor: pointer;

    &.active,
    &.active:hover {
      background-color: #354360;
      cursor: default;
    }

    &.disabled,
    &.disabled:hover {
      background-color: #1e2637;
      color: #838fa3;
      cursor: not-allowed;
    }
  }
}
